.tree {
	position: relative;
	font-size: 0.85rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;

	ul {
		padding-left: 4px;
		list-style: none;
		border-left: 0.5px dashed #979797;
		&:last-child {
			border: 0.5px dashed transparent;
		}

		li {
			position: relative;
			padding-top: 5px;
			padding-bottom: 5px;
			padding-left: 15px;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			.folder_info {
			}
			.has-child {
				margin-left: 16px;
			}
			.folder-name {
				text-overflow: ellipsis;
				cursor: pointer;
				margin-top: 5px;

				&:hover {
					// box-shadow: 0 0 0 2px #0069b1;
					// border-radius: 7px;
					// padding: 5px;
				}
			}
			.dashed-border {
				position: absolute;
				height: 40px;
				width: 13px;
				margin-top: -14px;
				margin-left: -5px;
				border-left: 0.5px dashed #979797;
				border-bottom: 2px dashed #979797;
				background-color: white;

				&:last-child {
					border-radius: 3px !important; //not working
				}
			}

			&:before {
				position: absolute;
				top: 15px;
				left: 0;
				width: 10px;
				height: 1px;
				margin: auto;
				content: "";
			}
			&:after {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 1px;
				height: 100%;
				content: "";
			}

			svg {
				// z-index: 1;
				position: relative;
				cursor: pointer;
			}
			img {
				z-index: 1;
				position: relative;
				cursor: pointer;
			}
		}

		a {
			cursor: pointer;

			&:hover {
				text-decoration: none;
			}
		}
	}
	.actions {
		font-size: 3px !important;
		float: right;
		visibility: hidden;
		margin-top: 0px;

		svg {
			height: 15px;
			width: 15px;
			margin: 3px;
		}
	}
	.folder-structure {
		padding: 2px 7px;
		margin-left: -13px;
		border: 0.1px solid transparent;
		.carets {
			position: absolute;
			margin-top: 9px;
			cursor: pointer;
		}
		.main-carets {
			margin-left: -23px;
			margin-top: 4px;
		}
		.main-item {
			position: absolute;
		}
	}
	.active {
		border: 0.5px solid #0069b1;
		border-radius: 5px;
		position: relative;
		z-index: 1;
	}

	.folder-structure:hover {
		border: 0.1px solid #0069b1;
		border-radius: 5px;
		position: relative;
		z-index: 1;
	}
	.folder-structure:hover .actions {
		visibility: visible;
		align-items: center;
	}
	.main-item-name {
		cursor: pointer;
	}
	.main-item-name:hover .actions {
		display: block;
		cursor: pointer;
	}
}
#multiple-option {
	.folder-icon-group {
		flex: 7;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-left: 15px;
		> img {
			margin-right: 5px;
			margin-left: -5px;
		}
		.folder-img {
			margin-left: -13px;
		}
		.main-item-name {
			flex: 0.9;
		}
	}

	button {
		border-radius: 50px;
	}
	.no-round {
		border-radius: 5px !important;
		border-bottom-left-radius: 0px !important;
		border-top-left-radius: 0px !important;
	}
	.no-right-radius {
		border-radius: 5px !important;
		border-bottom-right-radius: 0px !important;
		border-top-right-radius: 0px !important;
	}
	.button_icon {
		height: 25px;
		width: 25px;
	}
	.button_icon_big {
		height: 35px;
		width: 35px;
	}
	.MenuList {
		bottom: auto !important;
		top: 22px;
		z-index: 99;
		position: relative;
		left: auto;
		right: 72px;
	}
	.MenuItem {
		padding: 0 15px;
		line-height: 35px;
		white-space: nowrap;
		cursor: pointer;
	}
}
