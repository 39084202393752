#add-folder {
	.image input[type="file"] {
		-webkit-appearance: none;
		position: relative;
		top: 0;
		left: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
	.formRow {
		display: flex;
		label {
			margin: 8px;
			font-size: 13px;
			font-weight: 600;
			color: #2e2e2e;
		}
		input {
			margin-left: 44px;
			width: 70%;
		}
		.formRow_val {
			width: 70%;
		}
	}
	.actions {
		float: right;
		margin-top: 10%;
		svg {
			width: 20px;
			height: 20px;
			margin: -5px 9px;
		}
	}
	button {
		margin: 2px;
	}
}
