#catalogItemsList {

	.stats_wrapper li ~ li {
		margin-left: 25px  !important;
	}

	.stats_wrapper {
		margin-right: 30px;
		.stat_count {
			font-size: 30px;
		}
		.stat_label {
			margin-top: 0px;
			font-size: 20px;
		}
	}
	.catalogSearchBox {
		padding-left: 0px;
		padding-right: 0px;
		.search-group {
			width: 300px;
			margin: 0;
			position: relative;
			.search_icon {
				color: rgba($az_black, 0.5);
				position: absolute;
				height: 15px;
				width: 15px;
				top: 0;
				bottom: 0;
				left: 10px;
				margin: auto;
			}
			input[type="text"] {
				transition: all 0.25s;
				@include input(
					$bg_color: $az_gray_2,
					$border_color: rgba($az_black, 0.5)
				);
				@include input-placeholder-font(rgba($az_black, 0.4));
				color: $az_black;
				padding-left: 40px;
				border: 0;
				&:focus {
					padding-left: 15px;
					& + .search_icon {
						@include opacity(0);
					}
				}
			}
			@include respondToBelow(mid-size) {
				flex-basis: 100%;
			}
		}
	}

	.productIconContent {
		min-height: 400px;
		padding: 0px !important;
		.productContainer {
			display: flex;
			flex-wrap: wrap;
			column-gap: 20px;
			overflow-y: auto;
			height: 390px;
			margin-top: 20px;
			> div {
				flex: 40%;
				border: 2px solid $az_product_container_border;
				border-radius: 10px;
				box-shadow: 0px 0px 2px 0px rgba($az_black, 0.1);
				margin-bottom: 20px;
			}
			.productCard {
				width: 80%;
				padding: 10px;
				max-height: 180px;
				max-width: 480px;
				cursor: pointer;
				.productDetails {
					display: flex;
					align-items: flex-start;
					font-size: 14px;
					.productImage {
						position: relative;
						width: 100px;
						padding: 4px 10px;
						.productSelector {
							position: absolute;
							visibility: hidden;
							top: -3px;
							left: -3px;
							z-index: 1;
						}
						.visible {
							visibility: visible;
						}
					}
					.productDescription {
						width: 245px;
						display: flex;
						flex-direction: column;
						padding: 4px 10px;
						overflow: hidden;
						white-space: nowrap;
						.name {
							margin-bottom: 20px;
							font-size: 16px;
							font-weight: 500;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
						.brand {
							margin-bottom: 20px;
							font-size: 14px;
							font-weight: 500;
						}
						.productWeight {
							display: flex;
							font-size: 14px;
							margin-bottom: 20px;
							div {
								padding-right: 10px;
							}
						}
					}

					.quantity {
						display: flex;
						flex: 1;
						justify-content: flex-end;
						padding: 4px;
						font-size: 18px;
						font-weight: 500;
						.outOfStock {
							color: $az_red;
						}
						.low {
							color: $az_low_quantity;
						}
						.medium {
							color: $az_normal_quantity;
						}
					}
				}
				.costTagsSection {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 4px;
					.price {
						> div {
							padding: 4px;
						}
						.amount {
							font-size: 16px;
							font-weight: 500;
						}
					}
					.tags {
						.tagSelector {
							.tagList {
								display: flex;
								align-items: flex-start;
								flex-direction: column;
								.tagItem {
									padding: 5px 10px;

									&.solid {
										color: $az_cyan;
									}
									& ~ .tagItem {
										margin-top: 8px !important;
									}
									.tagIcon {
										color: $az_tag_color;
										display: inline-flex;
										align-items: center;
										justify-content: center;
										transform: scale(0.9) rotateY(180deg);
										margin-right: 5px;
									}
								}
								.inActive {
									background-color: $az_inactive !important;
								}
							}
						}
					}
				}
				&:hover {
					.productDetails {
						.productSelector {
							visibility: visible;
						}
					}
				}
			}
			.inActive {
				background-color: $az_inactive !important;
			}
			.emptyFolderContent {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.folderEmptyText {
					font-size: 28px;
					color: $az_empty_folder_text;
					margin-top: 15px;
				}
			}
		}
	}

	.headerSpace {
		padding-left: 0px;
		padding-right: 0px;
		padding-bottom: 0px;
	}
	.catalogItemHeader {
		.productName {
			display: flex;
			align-items: center;
			gap: 30px;
			.brand {
				color: $az_gray_5;
				font-weight: 100;
			}
		}
		.actions {
			.tableActions {
				margin-right: 15px;
				> li {
					color: $az_gray_5;
				}
			}
		}
	}
	.productViewHeader {
		.productViewOptions {
			justify-content: "space-between";
			align-items: "center";
			.listViewIcon {
				margin-right: 15px;
				.badge {
					position: relative;
					top: -15px;
					background: $az_badge_background;
					border-radius: 50%;
					color: $az_white;
					display: flex;
					justify-content: center;
					width: 20px;
					height: 20px;
					align-items: center;
				}
			}
			.faded {
				color: $az_gray_4;
			}
		}
	}

	.folder {
		padding: 25px 0 0;

		.folderExpand {
			color: $az_cyan;
			&:hover {
				cursor: pointer;
			}
		}

		.folderContainer {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
			margin-top: 15px;
			max-height: 320px;
			overflow-y: auto;
			.folderItem {
				width: 175px;
				margin-bottom: 20px;
				margin-top: -20px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				cursor: pointer;
				.folderBoxHead {
					width: 45%;
					min-height: 15px;
					border-bottom: none;
					border-left: 2px solid $az_gray_4;
					border-top: 2px solid $az_gray_4;
					border-right: 2px solid $az_gray_4;
					border-top-right-radius: 48px;
					border-top-left-radius: 20px;
					box-shadow: 0px 0px 6px 4px rgba($az_black, 0.1);
				}
				.folderBox {
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 100px;
					margin-right: 20px;
					border: 2px solid $az_gray_4;
					border-top-right-radius: 5px;
					border-bottom-right-radius: 5px;
					border-bottom-left-radius: 5px;
					box-shadow: 0px 0px 6px 4px rgba($az_black, 0.1);

					.image {
						width: 75px;
						height: 75px;
					}
				}
				.folderCog {
					text-align: right;
					position: relative;
					top: 50px;
					margin-right: 30px;
					visibility: hidden;
				}
				.folderName {
					font-weight: 500;
					margin-top: 10px;
					font-size: 16px;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				&:hover {
					.folderBox {
						border-color: $az_cyan;
					}
					.folderBoxHead {
						border-color: $az_cyan;
					}
					.folderCog {
						visibility: visible;
					}
				}
			}
		}
	}
}
